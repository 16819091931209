.card {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
}

/* for all social containers*/
.socialContainer {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #ffa31a;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition-duration: .3s;
}

/* instagram*/
.containerOne:hover {
    background-color: #d62976;
    transition-duration: .3s;
}

/* facebook*/
.containerTwo:hover {
    background-color: #00acee;
    transition-duration: .3s;
}

/* twitter*/
.containerThree:hover {
    background-color: #060707;
    transition-duration: .3s;
}

/* Whatsapp*/
.containerFour:hover {
    background-color: #128C7E;
    transition-duration: .3s;
}

.socialContainer:active {
    transform: scale(0.9);
    transition-duration: .3s;
}

.socialSvg {
    width: 17px;
}

.socialSvg path {
    fill: rgb(255, 255, 255);
}

.socialContainer:hover .socialSvg {
    animation: slide-in-top 0.3s both;
}

@keyframes slide-in-top {
    0% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}